import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_ERROR } from "./loginActionTypes";

const intitialState = {
    loading: false,
    data: [],
    msg: '',
    error: false
}

const loginReducer = (state = intitialState, action) => {

    switch (action.type) {

        case LOGIN_REQUEST:

            return {
                ...state, loading: true
            };
        case LOGIN_SUCCESS:

            return {
                loading: false,
                data: action.payload,
                msg: 'Success',
                error: false
            };
        case LOGIN_ERROR:

            return {
                loading: false,
                data: [],
                msg: action.payload,
                error: true
            };

        default:
            return state;

    }

}

export default loginReducer